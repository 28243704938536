/*
	System settings for transitioning between different stages of development
		-Copy/Paste section into active section when transitioning
		-Terser will remove all other comments when transpiling
*/

//URL Points based on staging status
/*
	//Development
	export const PRODUCTION_STATUS = false;
	export const API_ENDPOINT = 'https://localhost/h/AITApi/';
	export const TROPHY_DIRECTORY = 'http://localhost/h/jreact/src/assets/images/trophy/';

	//Staging
	export const PRODUCTION_STATUS = true;
	export const API_ENDPOINT = 'https://prototype.aitstaging.com/api/';
	export const TROPHY_DIRECTORY = 'https://prototype.aitstaging.com/img/trophy/';

	//Production
	export const PRODUCTION_STATUS = true;
	export const API_ENDPOINT = 'https://myaithub.com/api/';
	export const TROPHY_DIRECTORY = 'https://myaithub.com/img/trophy/';


*/

/**************************************************/
// Interface Settings/URLs
/**************************************************/
export const PRODUCTION_STATUS = true;
export const API_ENDPOINT = 'https://myaithub.com/api/';
export const TROPHY_DIRECTORY = 'https://myaithub.com/img/trophy/';
// export const PRODUCTION_STATUS = false;
// export const API_ENDPOINT = 'http://localhost/myaithub/AITApi/';
// export const TROPHY_DIRECTORY = 'http://localhost/myaithub/interface/src/assets/images/trophy/';
// export const API_ENDPOINT = 'http://bs-local.com/myaithub/AITApi/';
// export const TROPHY_DIRECTORY = 'http://bs-local.com/myaithub/interface/src/assets/images/trophy/';
// export const PRODUCTION_STATUS = false;
// export const API_ENDPOINT = 'https://dev.myaithub.com/api/';
// export const TROPHY_DIRECTORY = 'https://dev.myaithub.com/img/trophy/';
// export const PRODUCTION_STATUS = false;
// export const API_ENDPOINT = 'http://localhost/h - 2/AITApi/';
// export const TROPHY_DIRECTORY = 'https://dev.myaithub.com/img/trophy/';


/**************************************************/
// Country Variables
/**************************************************/
export const USA = 'USA';
export const CANADA = 'Canada';
export const DATABASE_UNKNOWN_COUNTRY = 'UNKNOWN';
export const INTERFACE_OTHER_COUNTRY = 'other';

/**************************************************/
// Permission, User Settings etc
/**************************************************/
export const GOD_LEVEL = 10;
export const DIRECTOR_LEVEL = 2;
export const REGIONAL_DIRECTOR_LEVEL = 3;
export const OVERSEER_LEVEL = 4;
export const SESSION_IDLE_MINUTES = 58; //changed from 12 to 58 9/29/2021
export const SESSION_TIME_STRING = 'lastActiveTime'; //For handling/limiting idling of users
export const GOD_LABEL = 'God Tier';
export const OVERSEER_LABEL = 'Overseer';
export const SESSION_TOKEN_KEY = 'CSRF_UUST';

//Manager Labels for permissions, change logs, etc
export const DEPT_MANAGER_LABELS = [
	{label: 'User', value: 0},
	{label: 'Assistant Manager', value: 1},
	{label: 'Manager', value: 2},
	{label: 'Overseer', value: OVERSEER_LEVEL},
	{label: GOD_LABEL, value: GOD_LEVEL}
];
//Actor manager labels for permissions, change logs, etc
export const ACTOR_MANAGER_LABELS = [
	{label: 'Actor', value: 0},
	{label: 'Assistant Director', value: 1},
	{label: 'Director', value: DIRECTOR_LEVEL},
	{label: 'Regional Director', value: REGIONAL_DIRECTOR_LEVEL},
	{label: GOD_LABEL, value: GOD_LEVEL}
];
//PPC labels
export const PPC_LABELS = [
	{label: 'Using Provided Wig', value: 0},
	{label: 'Owns Own Wig', value: 1},
	{label: 'Overseer', value: OVERSEER_LEVEL},
	{label: GOD_LABEL, value: GOD_LEVEL}
];
/**************************************************/
// DEPTS
/**************************************************/
export const PRIVATE_DEPT_ID = 2;
export const PRODUCTION_DEPT_ID = 3;
export const ACCOUNTING_DEPT_ID = 4;
export const ACTOR_DEPT_ID = 6; //Dept ID associated with actors (for handling permissions)
export const TECH_DEPT_ID = 7;
export const ADMIN_DEPT_ID = 8;
export const LOGISTICS_DEPT_ID = 9; //Logistics department

/**************************************************/
// COMPANIES
/**************************************************/
export const PPC_COMPANY_ID = 4; //PPC company ID
export const MMC_COMPANY_ID = 5; //Murdery Mystery company ID
export const FFAD_COMPANY_ID = 3; //Famous for a Day company ID
export const ZMM_COMPANY_ID = 6; //Zoom Murder Mystery Company ID
export const CZMM_COMPANY_ID = 7; //Zoom Corporate Company ID

/**************************************************/
// LABELS
/**************************************************/
export const SOUND_SYSTEM_REQUIRED_ID = 17; //Sound System Required ID
export const CLEAN_SHOW_RATING = 12; //Clean Show Rating ID
export const ZMM_TROUPE_ID = 29;  //Zoom Murder Mystery Troupe
export const NO_SOUND_SYSTEM_ID = 16; //No Sound System
export const SOUND_SYSTEM_RENTED_ID = 136 ; //Rented Sound System


/**************************************************/
// MISC -
/**************************************************/
export const ZMM_PHONE_UP_LEAD_SOURCE_IDS = [720,721]; //Phone lead sources for determining validation reqs when setting new leads
export const C_ZMM_PHONE_UP_LEAD_SOURCE_IDS = []; //Phone lead sources for corporate zoom
export const UNKNOWN_PAYMENT_SCHEDULE_ID = 0; //Undetermined payment schedule for (EditShowPaymentsDialog)
export const GOOGLE_MAP_API_KEY = 'AIzaSyDSZYtyWHBkVRgWv4YDHoRaLTAtb2nqEHU'; //For Actor Show Details Map
export const PRODUCTION_DEPT_PHONE = '(616) 454-9140'; //phone number for actor's to call if onboarding fails
export const PRODUCTION_DEPT_EMAIL = 'murdermysteryproduction@gmail.com'; //email for actors to contact if onboarding fails
export const DARK_THEME_MENU_COLOR = '#E0E0E0';
export const LIGHT_THEME_MENU_COLOR = '#1E1E1E';
export const NO_USER_STRING = '[System/Customer]'; //A consistent label when the system (or customer) made a change (changelog, customer notes...etc)
export const AWS_USER_KEY = 'AKIAWE46LTZQJGZS3V3F';//KEY UPDATED 3/2025
export const AWS_SECRET_KEY = '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';
